:root {
    --primary-color: #bfc849;
    --dark-text: #434d68;
    --blue-charcoal: #1e252c;
    --homii-grey: #d2d3d4;
    --light-grey: #f8f8f8;
    --dark-grey: #a2a2a2;
    --blue-zodiac: #434856;
}

.font-size-30 {
    font-size: 30px;
}

.general-image {
    object-fit: contain;
    max-width: 100%;
}

.card-image {
    object-fit: cover;
    width: 100%;
}

.hero-background {
    background-image: url('../images/hero_background.png');
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 90% auto;
}

.hero-mobile-search::placeholder {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11.7px;
    line-height: 140%;

    display: flex;
    align-items: center;

    color: #434856;

    opacity: 0.4;
}

@media only screen and (max-width: 700px) {
    .hero-background {
        background-image: url('../images/hero_background_2.png');
        background-repeat: no-repeat;
        background-position: 5% 10%;
        background-size: 45% auto;
    }
}

.about-section {
    background-color: var(--blue-charcoal);
    padding-top: 60px;

    background-image: url('../images/hanging_lamp.png');
    background-repeat: no-repeat;
    background-position: 30% -30%;
    background-size: auto 55%;

    margin-bottom: 160px;
}

@media only screen and (max-width: 700px) {
    .about-section {
        margin-bottom: 80px;
    }
}

@media only screen and (max-width: 1100px) {
    .about-section {
        padding-top: 120px;

        background-position: 100% -10%;
        background-size: 40% auto;
    }
}

@media only screen and (max-width: 800px) {
    .about-section {
        padding-top: 120px;

        background-position: 100% -12%;
        background-size: 50% auto;
    }

    .about-background {
        background-image: url('../images/about_section_background_mobile.png');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 90% auto;
    }
}

@media only screen and (max-width: 600px) {
    .about-section {
        padding-top: 120px;

        background-position: 120% -12%;
        background-size: 70% auto;
    }
}

.slogan-section-background {
    display: flex;

    background-image: url('../images/slog_section_background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
}

.about-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.slogan-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 5px;
    margin-right: 30px;
    background-color: transparent;
    border: 1px solid var(--homii-grey);
}

.about-image {
    width: 100%;
}

.header-heading {
    width: 90%;
    margin-top: 40px;
    margin-bottom: 40px;
}

@media only screen and (max-width: 700px) {
    .header-heading {
        width: 100%;
    }
}

.header-paragraph-text {
    line-height: 140%;
    letter-spacing: -0.02em;
    color: var(--dark-grey);
    margin-bottom: 0;
}

.nav-link {
    font-size: 16px;
    color: var(--dark-text);
    margin-left: 20px;
    margin-right: 20px;
}

.nav-link:hover {
    color: var(--homii-grey);
    background-color: transparent;
    text-decoration: none;
}

.footer-form {
    border: none;
    margin-top: 30px;
    margin-bottom: 30px;
}

.footer-list {
    list-style: none;
    margin-left: -40px;
}

.footer-list-item {
    margin-bottom: 10px;
}

.footer-light-text {
    color: var(--dark-grey);
}

.green-button {
    color: white;
    background-color: var(--primary-color);
    box-shadow: 0px 7px 34px rgba(49, 114, 97, 0.1021);
    border-radius: 3px;
    width: 136px;
    height: 52px;
}

.outline-button {
    color: var(--homii-grey);
    background: transparent;
    border: 1px solid var(--homii-grey);
    border-radius: 3px;
    width: 180px;
    height: 60px;
    font-weight: bold;
}

.outline-button-white {
    color: var(--blue-zodiac);
    background: white;
    border: 1px solid var(--homii-grey);
    border-radius: 3px;
    width: 180px;
    height: 60px;
    font-weight: bold;
}

.green-button:hover {
    background-color: var(--homii-grey);
    box-shadow: 0px 7px 34px rgba(49, 114, 97, 0.1021);
}

.menu-button {
    width: 52px;
    height: 52px;
    background-color: white;
    margin-right: 30px;
    border: 1px solid var(--homii-grey);
    box-shadow: 0px 7px 49px rgba(208, 208, 208, 0.2421);
}

.menu-container {
    background-color: #181d1f;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    padding: 50px 160px;
}

.menu-image {
    width: 160px;
    object-fit: contain;
}

.menu-list-item {
    padding: 0;
}

.menu-list-item:hover {
    background-color: transparent;
}

.menu-link {
    font-size: 16px;
    line-height: 200%;
    color: #b9b9b9;
}

.menu-link:hover,
.footer-link:hover {
    color: var(--primary-color);
}

.footer-link {
    font-size: 16px;
    line-height: 140%;
    color: var(--dark-grey);
    text-decoration: none;
}

.footer-links {
    margin-top: 20px;
}

.footer-border {
    background-color: #f4f4f4;
    width: 100%;
    height: 1px;
}

.app-download-buttons {
    width: 141px;
    object-fit: contain;
    margin-right: 10px;
}

@media only screen and (max-width: 925px) {
    .app-download-buttons {
        width: 141px;
    }
}

.drawer-link {
    font-size: 14px;
    color: var(--blue-charcoal);
}

.menu-image-title {
    font-weight: 900;
    font-size: 16px;
    color: white;
    margin: 0;
}

.menu-image-subtitle {
    font-weight: 300;
    font-size: 14px;
    color: #b9b9b9;
    margin: 0;
}

.socials-button {
    width: 48px;
    height: 48px;
    margin-right: 15px;
    background-color: #1e252c;
    border: 0.75px solid #1e252c;
}

.socials-button:hover {
    background: #151a1f;
}

.socials-image {
    height: 20px;
    object-fit: contain;
}

.expansion-panel {
    border: none;
    outline: none;
    box-shadow: none;
}

.drawer-heading {
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.Mui-expanded .drawer-heading {
    color: var(--primary-color);
}

.carousel-image {
    height: 100%;
    width: 90%;
}

.carousel-text {
    padding: 20px;
}

@media only screen and (min-width: 1800px) {
    .carousel-image {
        height: 90%;
    }
    .carousel-text {
        padding: 25px;
    }
}

@media only screen and (max-width: 1320px) {
    .carousel-image {
        height: 90%;
    }
}

@media only screen and (max-width: 700px) {
    .carousel-image {
        width: 100%;
    }
    .carousel-text {
        padding: 0;
    }
}

.new-buildings-item-1 {
    order: 1;
}

@media only screen and (max-width: 700px) {
    .new-buildings-item-1 {
        order: 2;
    }
}

.new-buildings-item-2 {
    order: 2;
}

@media only screen and (max-width: 700px) {
    .new-buildings-item-2 {
        order: 1;
    }
}

.MuiTab-root {
    text-align: left;
}

.scroll-area {
    --ms-overflow-style: 'none';
    scrollbar-width: 'none';
}

.scroll-area::-webkit-scrollbar {
    display: none;
}

.share-icons {
    margin-right: 10px;
    outline: none;
    border: none;
}

.MuiOutlinedInput-notchedOutline {
    border-color: white;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: white;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #f44336;
}

.MuiOutlinedInput-root.Mui-focused MuiOutlinedInput-notchedOutline {
    border-color: #bfc849;
}

.MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline {
    border-color: #bfc849;
}

.MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #bfc849;
}

.MuiSelect-icon {
    color: white;
}

.MuiIconButton-root {
    color: white;
}

.MuiListItem-button {
    color: white;
}

.MuiInput-underline:before {
    border-bottom-color: white;
}

.MuiPopover-paper {
    background-color: #1e252c;
}

input[type='date']::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

#google-tag-manager-iframe {
    display: none;
    visibility: hidden;
}

/*  Terms and conditions 
    Privacy Policy
*/
#terms_privacy_container h2{
    margin-top: 100px !important;
    margin-bottom: 20px !important;
}

#terms_privacy_container h6{    
    margin-bottom: 50px;
	color: #A2A2A2 !important;
}

#terms_privacy_container div{
    margin-bottom: 50px;    
}

#terms_privacy_container div p,
#terms_privacy_container div a{
    color: #A2A2A2 !important;
}

#terms_privacy_container div p strong span{
    color: #97b200 !important;
    font-weight: bold;
}

#terms_privacy_container div h4{
    margin-bottom: 24px;
}

#terms_privacy_container div ol{
    padding-left: 20px;
    color: #A2A2A2 !important;
}

#terms_privacy_container div ul li,
#terms_privacy_container div ul li p,
#terms_privacy_container div ol li p{    
    color: #A2A2A2 !important;
}

#terms_privacy_container div ol div{
    margin-top: 25px;
    margin-bottom: 0;
}

#terms_privacy_container div p a{    
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    color: #A2A2A2 !important;
}

#terms_privacy_container div ul{
    padding-left: 20px;    
}

#terms_privacy_container div h5 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 110%;
    letter-spacing: 0.02em;
    color: #fff;
    margin-top: 22px;
    margin-bottom: 22px;
}

#terms_privacy_container {
    opacity: 1 !important;
}

#container_opacity,
#container_opacity > div  {
    opacity: 1 !important;
}

.app-retry-buttons {
    margin-left: 15px;
}